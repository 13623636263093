import React from 'react';

import styles from './Project.module.css';

function Projects(props) {
  const Content = (
    <div>
      <h1 className={styles.Title}>{props.title}</h1>
      <h4>{props.subtitle}</h4>
      <p>{props.children}</p>
    </div>
  );

  const Image = (
    <div
      className={styles.ImageBox}
      style={{ backgroundImage: `url("${props.image}")` }}
    >
      {}
    </div>
  );

  return (
    <div className={styles.Root} ref={props.forwardedRef}>
      <div className={styles.SubRoot}>
        <div className={styles.Column}>{Content}</div>
        <div className={styles.Column}>{Image}</div>
      </div>
    </div>
  );
}

export default Projects;
