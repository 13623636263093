import React, { Fragment, useState, useEffect } from 'react';
import { TextField, Button, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import styles from './Message.module.css';
import CloseIcon from '@material-ui/icons/Close';

function Message(props) {
  const [visible, setVisible] = useState('none');
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [windowClasses, setWindowClasses] = useState([styles.Window]);

  useEffect(() => {
    if (visible === 'flex') {
      setWindowClasses([styles.Window, styles.WindowShow]);
    } else {
      setWindowClasses([styles.Window]);
    }
  }, [visible]);

  const getMessage = () => {
    if (inputValue.trim().length > 0) {
      setMessages((prevState) => [inputValue.substring(0, 20), ...prevState]);
      setInputValue('');
    }
  };

  return (
    <Fragment>
      <div className={styles.Root}>
        <div
          className={styles.Button}
          onClick={() => {
            setVisible((prevState) => (prevState === 'none' ? 'flex' : 'none'));
          }}
        >
          <ChatBubbleOutlineIcon className={styles.Icon} />
        </div>
      </div>
      <div className={windowClasses.join(' ')}>
        <div className={styles.WindowTitle}>
          <div className={styles.WindowTitle}>
            <div className={styles.TitleText}>Хотите обратиться?</div>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloseIcon
                className={styles.CloseButton}
                onClick={() => {
                  setVisible((prevState) =>
                    prevState === 'none' ? 'flex' : 'none'
                  );
                }}
              />
            </IconButton>
          </div>
        </div>
        <div className={styles.Messages}>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
        <div className={styles.WindowInput}>
          <TextField
            id="message"
            label="Напишите нам"
            style={{ width: '100%' }}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
          <Button
            style={{ fontSize: 'small' }}
            endIcon={<SendIcon />}
            onClick={getMessage}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Message;
