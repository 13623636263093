import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from './ShortAbout.module.css';

const useStyles = makeStyles({
  ImageApps: {
    backgroundImage: `url('/assets/Apps2.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  Line: {
    backgroundImage: `url('/assets/bg.jpg')`,
    backgroundPositionY: '100px',
    width: '100%',
    height: '100px',
  },
});

function ShortAbout(props) {
  const classes = useStyles();
  const [imageClasses, setImageClasses] = useState([
    classes.ImageApps,
    styles.ImagePosition,
  ]);

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    setImageClasses([
      styles.ImageShow,
      classes.ImageApps,
      styles.ImagePosition,
    ]);
  }, [classes.ImageApps]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <Fragment>
      <div className={styles.Root} ref={props.forwardedRef}>
        <div className={styles.SubRoot}>
          <div className={styles.Column}>
            <h1 className={styles.HeaderTitle}>
              Разработка веб и мобильных приложений
            </h1>
            <p>
              Мы разрабатываем приложения для вашего бизнеса. Оперативно
              продвигаем ваше дело вперед.
            </p>
          </div>
          <div className={styles.Column}>
            <div className={imageClasses.join(' ')}>{}</div>
          </div>
        </div>
        <div
          className={classes.Line}
          style={{ backgroundPositionY: scrollTop / 4 + 200 }}
        >
          {}
        </div>
      </div>
    </Fragment>
  );
}

export default ShortAbout;
