import React, { useRef, useEffect, useState } from 'react';

import styles from './InfoCard.module.css';
import Colors from '../../constants/Colors';

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function InfoCard(props) {
  const cardRef = useRef();
  const [imageClass, setImageClass] = useState([styles.ImageBox]);

  const Content = (
    <div>
      <h1 style={{ color: Colors.primary1 }}>{props.title}</h1>
      <p>{props.children}</p>
    </div>
  );

  const Image = (
    <div
      className={imageClass.join(' ')}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      &nbsp;
    </div>
  );

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    const visible = isInViewport(cardRef.current);
    if (visible) {
      setImageClass([styles.ImageBox, styles.Show]);
    }
  }, [scrollTop]);

  return (
    <div className={styles.Root} ref={cardRef}>
      <div className={styles.SubRoot} ref={props.forwardedRef}>
        <div className={styles.Column}>{props.left ? Content : Image}</div>
        <div className={styles.Column}>{!props.left ? Content : Image}</div>
      </div>
    </div>
  );
}

export default InfoCard;
