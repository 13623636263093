import React, { useEffect, useRef } from 'react';
import styles from './App.module.css';
import { CssBaseline } from '@material-ui/core';

import Header from './components/Header/Header';
import ShortAbout from './components/ShortAbout/ShortAbout';
import InfoCard from './components/InfoCard/InfoCard';
import Projects from './components/Projects/Projects';
import Logos from './components/Logos/Logos';
import Footer from './components/Footer/Footer';
import Message from './components/Message/Message';

function App() {
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    document.title = 'DewStudio - разработка веб и мобильных приложений';
  }, []);

  return (
    <div
      className={styles.Root}
      style={{
        backgroundColor: '#1f3542',
      }}
    >
      <CssBaseline />
      <Header refs={refs} />
      <ShortAbout forwardedRef={refs[0]} />
      <InfoCard
        left={false}
        forwardedRef={refs[1]}
        title="Планирование, Дизайн и Разработка"
        image="/assets/business_mini.jpg"
      >
        Наша команда воплотит ваши бизнес идеи в готовый продукт. Мы пройдем по
        пути разработки вместе, чтобы получить результат максимально
        соответствующий вашим запросам. Создадим дизайн и интерфейс как
        мобильного так и веб приложения. Нужен ли вам SaaS проект, проект
        социальной сети, магазин для мобильного телефона или приложение для
        планирования задач, можете рассчитывать на нас.
      </InfoCard>
      <InfoCard
        left={true}
        title="Быстрый вывод в работу"
        image="/assets/team_mini.jpg"
      >
        Любое новое начало связано с рисками. Поэтому наша задача максимально
        снизить эти риски. Мы оптимизируем работу, чтобы избежать потери времени
        и ускорить разработку проекта. Скорость наше всё.
      </InfoCard>
      <InfoCard
        left={false}
        title="Работаем в команде"
        image="/assets/support_mini.jpg"
      >
        Мы поможем вашему приложению стать успешным, но даже после успешного
        выхода на рынок, ваше приложение не останется без поддержки. При
        необходимости мы будем улучшать и перестраивать отдельные его
        компоненты. Мы поможем вам и вашему приложению всегда оставаться
        актуальными.
      </InfoCard>
      <Projects
        title="Zaconta - учет автомобильных запчастей"
        subtitle="Backend • UX/UI Design • Web Development • App Development"
        image="/assets/zaconta.png"
        forwardedRef={refs[2]}
      >
        Программа для авторазборок и автомагазинов. Учет, продажа автомобильных
        доноров, запчастей. Выгрузка наличия запасных частей в формате
        совместимом с популярными сайтами по продаже запчастей, досками
        объявлений. Построение отчетов о движении товаров, себестоимости, и тд.
      </Projects>
      <Logos />
      <Footer forwardedRef={refs[3]} />
      <Message />
    </div>
  );
}

export default App;
