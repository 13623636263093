import React from 'react';

import styles from './Logos.module.css';

function Logos(props) {
  return (
    <div className={styles.Root}>
      <div
        className={styles.Logo}
        style={{ backgroundImage: `url("/assets/aspnet_color.png")` }}
      >
        {}
      </div>
      <div
        className={styles.Logo}
        style={{ backgroundImage: `url("/assets/vue_color.png")` }}
      >
        {}
      </div>
      <div
        className={styles.Logo}
        style={{ backgroundImage: `url("/assets/react_color.png")` }}
      >
        {}
      </div>
      <div
        className={styles.Logo}
        style={{ backgroundImage: `url("/assets/sql_color.png")` }}
      >
        {}
      </div>
      <div
        className={styles.Logo}
        style={{ backgroundImage: `url("/assets/docker_color.png")` }}
      >
        {}
      </div>
    </div>
  );
}

export default Logos;
