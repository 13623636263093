import React from 'react';
import { AppBar, IconButton, Toolbar, Menu, MenuItem } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { makeStyles } from '@material-ui/core/styles';
import scrollToComponent from 'react-scroll-to-component';

import styles from './Header.module.css';
import Colors from '../../constants/Colors';

const useStyles = makeStyles((theme) => ({
  AppBar: {
    backgroundImage: 'url("/assets/bg.jpg")',
    fontFamily: 'Noto Sans HK',
  },
  TopMenu: {
    fontFamily: 'Noto Sans HK',
  },
}));

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id) => {
    setAnchorEl(null);
    if (props.refs[id]) {
      scrollToComponent(props.refs[id].current);
    }
  };

  return (
    <div className={styles.Root}>
      <AppBar className={classes.AppBar} elevation={0}>
        <Toolbar className={styles.AppBarWrapper}>
          <h1 className={styles.AppTitle}>
            <span style={{ color: Colors.primary0 }}>Dew</span>
            <span>Studio</span>
          </h1>
          <IconButton onClick={handleClick}>
            <DehazeIcon className={styles.Icon} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.TopMenu}
          >
            <MenuItem onClick={() => handleClose(0)}>Домой</MenuItem>
            <MenuItem onClick={() => handleClose(1)}>Этапы</MenuItem>
            <MenuItem onClick={() => handleClose(2)}>Наши работы</MenuItem>
            <MenuItem onClick={() => handleClose(3)}>Контакты</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
