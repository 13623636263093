import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';

import styles from './Footer.module.css';
import Colors from '../../constants/Colors';

const useStyles = makeStyles({
  Root: {
    backgroundImage: `url('/assets/bg.jpg')`,
    backgroundPositionY: '450px',
  },
});

function Footer(props) {
  const classes = useStyles();

  return (
    <div className={classes.Root} ref={props.forwardedRef}>
      <div className={styles.SubRoot}>
        <div className={styles.Card}>
          <h1 className={styles.AppTitle}>
            <span style={{ color: Colors.primary0 }}>Dew</span>
            <span>Studio</span>
          </h1>
          <p>
            <FacebookIcon className={styles.Icon} />
            <InstagramIcon className={styles.Icon} />
            <GitHubIcon className={styles.Icon} />
            <TwitterIcon className={styles.Icon} />
          </p>
          <p>
            email:{' '}
            <span style={{ color: Colors.secondaryB3 }}>
              <a href="mailto:info@dewstudio.ru?subject=Mail from dewstudio.ru">
                info@dewstudio.ru
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
