const Colors = {
  primary0: '#14d100',
  primary1: '#329d27',
  primary2: '#0d8800',
  primary3: '#4ae83a',
  primary4: '#74e868',
  secondaryA0: '#ff6f00',
  secondaryA1: '#bf6e30',
  secondaryA2: '#a64800',
  secondaryA3: '#ff9340',
  secondaryA4: '#ffb073',
  secondaryB0: '#c9007a',
  secondaryB1: '#97266a',
  secondaryB2: '#c9007a',
  secondaryB3: '#e439a1',
  secondaryB4: '#e467b3',
};

export default Colors;

// https://colorscheme.ru/#2N31Tw0w0w0w0
